<template>
  <v-card>
    <v-card-title>
      <menuTitle :title="module ? 'Edit Module' : 'New Module'"></menuTitle>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-text-field
            label="Name"
            v-model="tmpModule.modulename"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Internal"
            v-model="tmpModule.internalid"
            outlined
            hide-details
            :disabled="module != null"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-select
            label="Application"
            outlined
            v-model="tmpModule.applicationid"
            :items="applications"
            :item-text="(item) => item.name"
            :item-value="(item) => item.applicationid"
            :disabled="module != null"
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="Price"
            v-model="tmpModule.price"
            outlined
            hide-details
            type="number"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="Monthly Price"
            v-model="tmpModule.monthlyprice"
            outlined
            hide-details
            type="number"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            label="Description"
            v-model="tmpModule.moduledescription"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn outlined @click="$emit('done')">
        <span>Cancel</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        color="#b31616"
        @click="module ? applyPatch() : applyPost()"
      >
        <span>{{ module ? "Save" : "Add" }}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import menuTitle from "./helpers/menu_title.vue";
export default {
  name: "moduleDialog",
  components: {
    menuTitle,
  },
  props: {
    module: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters({ applications: "allApplications", newModule: "newModule" }),
  },
  data: () => ({
    tmpModule: {},
  }),
  mounted() {
    if (this.module) {
      this.tmpModule = JSON.parse(JSON.stringify(this.module));
    } else {
      this.tmpModule = JSON.parse(JSON.stringify(this.newModule));
    }
  },
  methods: {
    ...mapActions({
      post: "postModule",
      patch: "patchModule",
      showSnackbar: "showSnackbar",
    }),
    applyPost: function () {
      this.post(this.tmpModule).then((error) => {
        if (error) {
          this.showSnackbar("Module could not be added. Error: " + error);
        } else {
          this.showSnackbar("Module has been added");
          this.$emit("done");
        }
      });
    },
    applyPatch: function () {
      this.patch(this.tmpModule).then((error) => {
        if (error) {
          this.showSnackbar("Module could not be updated. Error: " + error);
        } else {
          this.showSnackbar("Module has been updated");
          this.$emit("done");
        }
      });
    },
  },
};
</script>

<style>
</style>