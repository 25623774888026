<template>
  <v-card height="100%">
    <v-card-title>
      <v-container fluid>
        <v-row>
          <v-col cols="6" align="left" class="nopad_class">
            <v-container align="left" class="nopad_class">
              <span align="left" class="title_class"> Applications </span>
            </v-container>
          </v-col>
          <v-col cols="6" align="right" class="nopad_class">
            <v-btn
              outlined
              color="#b31616"
              @click="
                selectedApplication = null;
                dialog = true;
              "
            >
              New
              <v-icon small class="ml-1"> mdi-plus </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :items="applications"
        :custom-filter="customFilter"
        :headers="headers"
        :items-per-page="5"
        :search="search"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td align="center">{{ item.applicationid }}</td>
            <td align="center">{{ item.name }}</td>
            <td align="center">{{ item.description }}</td>
            <td align="center">{{ item.type }}</td>
            <td align="right">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    icon
                    color="#b31616"
                    @click="
                      selectedApplication = item;
                      dialog = true;
                    "
                  >
                    <v-icon>mdi-cog-outline</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-on="on"
                    v-bind="attrs"
                    color="#b31616"
                    @click="
                      selectedApplication = item;
                      deleteDialog = true;
                    "
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>

    <accept-dialog
      v-model="deleteDialog"
      v-if="deleteDialog"
      title="Delete Application?"
      text="Are you sure you want to delete this application?"
      @accepted="deleteApplicationById(selectedApplication.applicationid)"
    >
    </accept-dialog>

    <v-dialog v-model="dialog" max-width="1500" persistent>
      <application-dialog
        v-if="dialog"
        :application="selectedApplication"
        @done="dialog = false"
      ></application-dialog>
    </v-dialog>
  </v-card>
</template>

<script>
import applicationDialog from "./license_settings_application_dialog.vue";
import { mapActions, mapGetters } from "vuex";
import acceptDialog from "./helpers/generic_accept_dialog.vue";
export default {
  name: "applicationsCard",
  components: {
    applicationDialog,
    acceptDialog
  },
  props: {
    search: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    deleteDialog: false,
    dialog: false,
    selectedApplication: null,
    headers: [
      {
        text: "Id",
        value: "applicationid",
        align: "center",
        sortable: false,
        filterable: true,
      },
      {
        text: "Name",
        value: "name",
        align: "center",
        sortable: false,
        filterable: true,
      },
      {
        text: "Description",
        value: "name",
        align: "center",
        sortable: false,
        filterable: false,
        //width: "stretch",
      },
      {
        text: "Type",
        value: "name",
        align: "center",
        sortable: false,
        filterable: false,
        //width: "stretch",
      },
      {
        text: "",
        value: "actions",
        align: "center",
        sortable: false,
        filterable: false,
        width: "strech",
      },
    ],
  }),
  mounted() {
    this.$store.dispatch("fetchApplications");
  },
  computed: {
    ...mapGetters({ applications: "allApplications" }),
  },
  methods: {
    ...mapActions({
      deleteApplication: "deleteApplication",
      showSnackbar: "showSnackbar",
    }),
    customFilter: function (_, search, filter) {},
    deleteApplicationById(applicationid) {
      const error = this.deleteApplication(applicationid);
      if (error) {
        this.showSnackbar("Error while deleting Application. Error:" + error);
      }
      this.showSnackbar("Application successfuly deleted");
    },
  },
};
</script>

<style>
.nopad_class {
  padding-left: 0px !important;
  padding-top: 0px !important;
}
.title_class {
  font-size: 1.8rem;
  color: #b31616;
}
</style>