<template>
  <v-dialog
    v-model="dialog"
    max-width="450"
  >
    <v-card>
      <v-card-title>
        <menu-title title="New Duration Discount"></menu-title>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              type="number"
              outlined
              hide-details
              label="Duration (Months)*"
              v-model="tmpDurationdiscount.duration"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              type="number"
              outlined
              hide-details
              label="Discount (%)*"
              v-model="tmpDurationdiscount.discount"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          class="ml-2"
          @click="dialog=false"
        >
          <span>Cancel</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          color="#b31616"
          class="mr-2"
          @click="addDiscount"
        >
          <span>Add</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import menuTitle from "./helpers/menu_title.vue";
export default {
  name: "quantityDiscountDialog",
  components: {
    menuTitle,
  },
  props: {
    durationDiscounts: Array,
    value: Boolean,
  },
  data: () => ({
    tmpDurationdiscount: {},
  }),
  computed: {
    ...mapGetters({
      newDurationDiscount: "newDurationDiscount",
    }),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions({
      showSnackbar: "showSnackbar",
    }),
    addDiscount: function () {
      if (
        this.tmpDurationdiscount.duration > 0 &&
        this.tmpDurationdiscount.discount > 0
      ) {
        this.durationDiscounts.push(this.tmpDurationdiscount);
        this.durationDiscounts.sort((a, b) => {
          return a.duration - b.duration;
        });
        this.dialog = false;
      } else {
        this.showSnackbar(
          "Fill in the required fields to continue. Duration and Discount should be greater than zero."
        );
      }
    },
  },
  async mounted() {
    this.tmpDurationdiscount = JSON.parse(
      JSON.stringify(this.newDurationDiscount)
    );
  },
};
</script>

<style>
</style>