<template>
  <v-dialog
    v-model="dialog"
    max-width="450"
  >
    <v-card>
      <v-card-title>
        <menu-title title="New Quantity Discount"></menu-title>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              hide-details
              label="Quantity*"
              v-model="tmpQuantityDiscount.amount"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              hide-details
              label="Discount (%)*"
              v-model="tmpQuantityDiscount.discount"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          class="ml-2"
          @click="dialog=false"
        >
          <span>Cancel</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          color="#b31616"
          class="mr-2"
          @click="addDiscount"
        >
          <span>Add</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import menuTitle from "./helpers/menu_title.vue";
export default {
  name: "quantityDialog",
  components: {
    menuTitle,
  },
  props: {
    quantityDiscounts: Array,
    value: Boolean,
  },
  data: () => ({
    tmpQuantityDiscount: {},
  }),
  computed: {
    ...mapGetters({
      newQuantitydiscount: "newQuantitydiscount",
    }),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions({
      showSnackbar: "showSnackbar",
    }),
    addDiscount: function () {
      console.log("adding...");
      if (
        this.tmpQuantityDiscount.amount > 0 &&
        this.tmpQuantityDiscount.discount > 0
      ) {
        this.quantityDiscounts.push(this.tmpQuantityDiscount);
        this.quantityDiscounts.sort((a, b) => {
          return a.amount - b.amount;
        });
        this.dialog = false;
      } else {
        this.showSnackbar(
          "Fill in the required fields to continue. Amount and Discount should be greater than zero."
        );
      }
    },
  },
  async mounted() {
    this.tmpQuantityDiscount = JSON.parse(
      JSON.stringify(this.newQuantitydiscount)
    );
  },
};
</script>

<style>
</style>