<template>
  <v-card>
    <v-card-title>
      <menuTitle
        :title="licensetemplate ? 'View License' : 'New License'"
        class="mt-6 mx-3"
      ></menuTitle>
    </v-card-title>
    <v-card-text>
      <v-row class="mx-0">
        <v-col cols="6">
          <v-text-field
            outlined
            hide-details
            v-model="tmpTemplate.name"
            label="Name*"
            :disabled="isDisabled"
          >
          </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-select
            outlined
            hide-details
            v-model="tmpTemplate.applicationid"
            :items="allApplications"
            :item-text="(app) => app.name"
            :item-value="(app) => app.applicationid"
            :disabled="isDisabled"
            label="Application*"
            @change="modulesSelected = []"
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-text-field
            outlined
            hide-details
            type="number"
            v-model="tmpTemplate.freenfr"
            label="Free NFR Licenses"
            :disabled="isDisabled"
          ></v-text-field>
        </v-col>

        <v-col cols="12" v-if="tmpTemplate.applicationid" align="left">
          <label v-if="!isDisabled">Select modules:</label>
          <label v-else>
            {{
              templateModulesByTemplateId(tmpTemplate.id).length > 1
                ? "Modules:"
                : "Module:"
            }}
          </label>
        </v-col>

        <v-row v-if="isDisabled" class="mx-0">
          <v-col
            cols="4"
            v-for="(tmplmod, index) in templateModulesByTemplateId(
              tmpTemplate.id
            )"
            :key="index"
          >
            <v-card outlined>
              <v-card-title class="card-title ml-1">
                {{ moduleByModuleid(tmplmod.moduleid).modulename }}
              </v-card-title>
              <v-card-text align="left" class="mx-1">
                <b>{{ moduleByModuleid(tmplmod.moduleid).moduledescription }}</b
                ><br />
                Price:
                <b>{{ moduleByModuleid(tmplmod.moduleid).price + "€" }}</b
                ><br />
                Monthly:
                <b>{{
                  moduleByModuleid(tmplmod.moduleid).monthlyprice + "€"
                }}</b>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else class="mx-0">
          <v-col
            cols="4"
            v-for="mod in modulesByApplicationid(tmpTemplate.applicationid)"
            :key="mod.moduleid"
          >
            <v-card outlined>
              <v-card-title class="card-title ml-1">
                {{ mod.modulename }}
                <v-spacer></v-spacer>
                <v-checkbox
                  :value="getModuleSelected(mod.moduleid)"
                  @change="setModuleSelected($event, mod.moduleid)"
                  hide-details
                ></v-checkbox>
              </v-card-title>
              <v-card-text align="left" class="mx-1">
                <b>{{ mod.moduledescription }}</b
                ><br />
                Price: <b>{{ mod.price + "€" }}</b
                ><br />
                Monthly: <b>{{ mod.monthlyprice + "€" }}</b>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-col cols="12" class="mt-2">
          <v-textarea
            outlined
            hide-details
            rows="1"
            label="Description"
            auto-grow
            v-model="tmpTemplate.description"
            :disabled="isDisabled"
          ></v-textarea>
        </v-col>
        <v-col cols="2">
          <v-text-field
            outlined
            hide-details
            type="number"
            label="Maintenancefee (%)"
            v-model="tmpTemplate.partnermaintenancefee"
            :disabled="isDisabled"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            outlined
            hide-details
            type="number"
            label="Partner Discount (%)"
            v-model="tmpTemplate.partnerdiscount"
            :disabled="isDisabled"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            outlined
            hide-details
            type="number"
            label="Monthly Price"
            v-model="tmpTemplate.monthlyprice"
            :disabled="isDisabled || autoPricing"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            outlined
            hide-details
            type="number"
            label="Total Price"
            v-model="tmpTemplate.price"
            :disabled="isDisabled || autoPricing"
          ></v-text-field>
        </v-col>
        <v-col cols="2" align="right">
          <v-checkbox
            hide-details
            v-model="autoPricing"
            label="Auto-Pricing?"
            color="#b31616"
            @change="autoSetPrice()"
            :disabled="isDisabled"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="6" align="center">
          <v-card outlined>
            <v-card-title align="left">
              <label class="mx-3">Quantity Discounts:</label>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="isDisabled"
                outlined
                icon
                color="#b31616"
                @click="quantityDialog = true"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-simple-table>
                <thead>
                  <tr>
                    <th align="left">Starting at</th>
                    <th align="left">Discount (%)</th>
                    <th align="right" width="1%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(q, i) in quantityDiscounts" :key="i">
                    <td align="left">{{ q.amount }}</td>
                    <td align="left">{{ q.discount }}</td>
                    <td align="right" width="1%">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-on="on"
                            v-bind="attrs"
                            color="#b31616"
                            icon
                            class="mr-0"
                            @click="deleteQuantityDiscount(q)"
                            :disabled="isDisabled"
                          >
                            <v-icon>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" align="center">
          <v-card outlined>
            <v-card-title align="left">
              <label class="mx-3">Duration Discounts:</label>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="isDisabled"
                outlined
                icon
                color="#b31616"
                @click="durationDialog = true"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-simple-table>
                <thead>
                  <tr>
                    <th align="left">Starting at</th>
                    <th align="left">Discount (%)</th>
                    <th align="right" width="1%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(d, i) in durationDiscounts" :key="i">
                    <td align="left">{{ d.duration }}</td>
                    <td align="left">{{ d.discount }}</td>
                    <td align="right" width="1%">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-on="on"
                            v-bind="attrs"
                            color="#b31616"
                            icon
                            class="mr-0"
                            @click="deleteDurationDiscount(d)"
                            :disabled="isDisabled"
                          >
                            <v-icon>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn large outlined class="ml-5 mb-2" @click="$emit('done')">
        <span>Cancel</span>
      </v-btn>
      <v-spacer></v-spacer>

      <v-btn
        large
        outlined
        class="mr-5 mb-2"
        color="primary"
        @click="licensetemplate ? update() : add()"
        :disabled="isDisabled"
      >
        <span>Add</span>
      </v-btn>
    </v-card-actions>

    <duration-dialog
      v-if="durationDialog"
      v-model="durationDialog"
      :durationDiscounts="durationDiscounts"
    ></duration-dialog>

    <quantity-dialog
      v-if="quantityDialog"
      v-model="quantityDialog"
      :quantityDiscounts="quantityDiscounts"
    ></quantity-dialog>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import menuTitle from "./helpers/menu_title.vue";
import durationDialog from "./license_settings_licensetemplate_duration_dialog.vue";
import quantityDialog from "./license_settings_licensetemplate_quantity_dialog.vue";
export default {
  name: "licensetemplateDialog",
  components: {
    menuTitle,
    durationDialog,
    quantityDialog,
  },
  props: {
    licensetemplate: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    tmpTemplate: {},
    modulesSelected: [],
    autoPricing: true,

    quantityDiscounts: [],
    durationDiscounts: [],

    quantityDialog: false,
    durationDialog: false,
  }),
  watch: {
    modulesSelected() {
      if (this.autoPricing) {
        this.autoSetPrice();
      }
    },
  },
  computed: {
    checkedModules: {
      get(value) {
        console.log("getting...");
        console.log(value);
      },
      set(value) {
        console.log("setting...");
        console.lgo(value);
      },
    },
    isDisabled: function () {
      if (this.licensetemplate) return true;
      else return false;
    },
    ...mapGetters({
      newTemplate: "newTemplate",
      newTemplateModule: "newTemplateModule",
      allApplications: "allApplications",
      moduleByModuleid: "moduleByModuleid",
      modulesByApplicationid: "modulesByApplicationid",
      quantitydiscountsByTemplateId: "quantitydiscountsByTemplateId",
      durationdiscountsByTemplateId: "durationdiscountsByTemplateId",
      templateModulesByTemplateId: "templateModulesByTemplateId",
    }),
  },
  methods: {
    ...mapActions({
      postTemplate: "postTemplate",
      showSnackbar: "showSnackbar",
      postQuantitydiscount: "postQuantitydiscount",
      postDurationdiscount: "postDurationdiscount",
      postTemplateModule: "postTemplateModule",
      fetchModulesAsync: "fetchModulesAsync",
      fetchTemplatesAsync: "fetchTemplatesAsync",
      fetchApplicationsAsync: "fetchApplicationsAsync",
      fetchTemplateModulesAsync: "fetchTemplateModulesAsync",
      fetchQuantitydiscountsAsync: "fetchQuantitydiscountsAsync",
      fetchDurationdiscountsAsync: "fetchDurationdiscountsAsync",
    }),
    deleteDurationDiscount: function (dd) {
      console.log("deleting dd...");
      let index = this.durationDiscounts.findIndex((x) => {
        return (
          x.templateid == dd.templateid &&
          x.duration == dd.duration &&
          x.discount == dd.discount
        );
      });
      this.durationDiscounts.splice(index, 1);
    },
    deleteQuantityDiscount: function (qd) {
      console.log("deleting qd...");
      let index = this.quantityDiscounts.findIndex((x) => {
        return (
          x.templateid == qd.templateid &&
          x.amount == qd.amount &&
          x.discount == qd.discount
        );
      });
      this.quantityDiscounts.splice(index, 1);
    },
    autoSetPrice: function () {
      var price = parseFloat(0.0);
      var monthlyprice = parseFloat(0.0);
      this.modulesSelected.forEach((moduleid) => {
        let mod = this.moduleByModuleid(moduleid);
        if (mod.price) price += parseFloat(mod.price);
        if (mod.monthlyprice) monthlyprice += parseFloat(mod.monthlyprice);
      });
      this.tmpTemplate.price = price;
      this.tmpTemplate.monthlyprice = monthlyprice;
    },
    add: function () {
      console.log("adding...");
      this.postTemplate(this.tmpTemplate).then(
        (response) => {
          if (response) {
            var licensetemplateId = response.id;
            this.quantityDiscounts.forEach((qd) => {
              qd.templateid = licensetemplateId;
              this.postQuantitydiscount(qd).catch((err) => {
                this.showSnackbar(
                  "Quantity discount could not be added. Error: {0}".format(err)
                );
              });
            });
            this.durationDiscounts.forEach((dd) => {
              dd.templateid = licensetemplateId;
              this.postDurationdiscount(dd).catch((err) => {
                this.showSnackbar(
                  "Duration discount could not be added. Error: {0}".format(err)
                );
              });
            });
            this.modulesSelected.forEach((mod) => {
              var tmpmod = JSON.parse(JSON.stringify(this.newTemplateModule));
              tmpmod.id = licensetemplateId;
              tmpmod.moduleid = mod;
              this.postTemplateModule(tmpmod).catch((err) => {
                this.showSnackbar(
                  "Template Module could not be added. Error: {0}".format(err)
                );
              });
            });
            this.showSnackbar("License Template has been added.");
            this.$emit("done");
          }
        },
        (error) => {
          if (error) {
            this.showSnackbar(
              "Licensetemplate could not be added. Error: {0}".format(error)
            );
          }
        }
      );
    },
    update: function () {},
    getModuleSelected: function (moduleid) {
      return this.modulesSelected.includes(moduleid);
    },
    setModuleSelected: function (event, moduleid) {
      if (event) {
        this.modulesSelected.push(moduleid);
      } else {
        this.modulesSelected.splice(this.modulesSelected.indexOf(moduleid), 1);
      }
    },
  },
  async mounted() {
    await this.fetchModulesAsync();
    await this.fetchTemplatesAsync();
    await this.fetchApplicationsAsync();
    await this.fetchTemplateModulesAsync();
    await this.fetchQuantitydiscountsAsync();
    await this.fetchDurationdiscountsAsync();
    if (this.licensetemplate) {
      this.quantityDiscounts = this.quantitydiscountsByTemplateId(
        this.licensetemplate.id
      );
      this.durationDiscounts = this.durationdiscountsByTemplateId(
        this.licensetemplate.id
      );
      this.tmpTemplate = JSON.parse(JSON.stringify(this.licensetemplate));
    } else {
      this.tmpLicensetemplate = JSON.parse(JSON.stringify(this.newTemplate));
    }
  },
};
</script>

<style>
</style>