<template>
  <v-card>
    <v-card-title>
      <menuTitle
        :title="application ? 'Edit Application' : 'New Application'"
      ></menuTitle>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="4">
          <v-text-field
            hide-details
            label="Type"
            v-model="tmpApplication.type"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="8">
          <v-text-field
            hide-details
            label="Name"
            v-model="tmpApplication.name"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea
            hide-details
            rows="1"
            auto-grow
            label="Description"
            v-model="tmpApplication.description"
            outlined
          ></v-textarea>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn outlined @click="$emit('done')">
        <span>Cancel</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        color="#b31616"
        @click="application ? applyPatch() : applyPost()"
      >
        <span>{{ application ? "Save" : "Add" }}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import menuTitle from "./helpers/menu_title.vue";
export default {
  name: "applicationDialog",
  components: {
    menuTitle,
  },
  props: {
    application: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      newApplication: "newApplication",
      allApplications: "allApplications",
    }),
  },
  data: () => ({
    changed: -1,
    tmpApplication: {},
  }),
  updated() {
    this.changed++;
  },
  mounted() {
    console.log("mounted");
    if (this.application) {
      this.tmpApplication = JSON.parse(JSON.stringify(this.application));
    } else {
      this.tmpApplication = JSON.parse(JSON.stringify(this.newApplication));
    }
  },
  methods: {
    ...mapActions({
      post: "postApplication",
      patch: "patchApplication",
      showSnackbar: "showSnackbar",
    }),
    applyPost: function () {
      if (
        this.allApplications.some((e) => e.type == this.tmpApplication.type)
      ) {
        this.showSnackbar("Application type already in use");
      } else {
        this.post(this.tmpApplication).then((error) => {
          if (error) {
            this.showSnackbar(
              "Application could not be added. Error: " + error
            );
          } else {
            this.showSnackbar("Application has been added");
            this.$emit("done");
          }
        });
      }
    },
    applyPatch: function () {
      if (this.changed == 0) {
        this.$emit("done");
        return;
      }
      if (
        this.allApplications.some(
          (e) =>
            e.type == this.tmpApplication.type &&
            e.applicationid != this.tmpApplication.applicationid
        )
      ) {
        this.showSnackbar("Application type already in use");
      } else {
        this.patch(this.tmpApplication).then((error) => {
          if (error) {
            this.showSnackbar(
              "Application could not be updated. Error: " + error
            );
          } else {
            this.showSnackbar("Application has been updated.");
            this.$emit("done");
          }
        });
      }
    },
  },
};
</script>

<style>
</style>