<template>
  <v-snackbar v-model="snack.show" :timeout="snack.timeout">
    <slot></slot>

    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="hideSnackbar()">
        <span>Close</span>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "snackbar",
  methods: {
    ...mapActions({
      hideSnackbar: "hideSnackbar",
    }),
  },
  computed: {
    ...mapGetters({
      snack: "getSnackbar",
    }),
  },
};
</script>

<style>
</style>