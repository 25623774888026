<template>
  <v-card height="100%">
    <v-card-title>
      <v-container fluid>
        <v-row>
          <v-col cols="6" align="left" class="nopad_class">
            <v-container align="left" class="nopad_class">
              <span align="left" class="title_class"> License Templates </span>
            </v-container>
          </v-col>
          <v-col cols="6" align="right" class="nopad_class">
            <v-btn
              outlined
              color="primary"
              @click="
                selectedLicensetemplate = null;
                dialog = true;
              "
            >
              New
              <v-icon small class="ml-1"> mdi-plus </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :items="licensetemplates"
        :headers="headers"
        :search="search"
        :custom-filter="customFilter"
        :items-per-page="5"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td align="center">{{ item.applicationid }}</td>
            <td align="center">{{ item.name }}</td>
            <td align="center">{{ item.description }}</td>
            <td align="center">{{ item.price ? item.price + "€" : "-" }}</td>
            <td align="center">
              {{ item.monthlyprice ? item.monthlyprice + "€" : "-" }}
            </td>
            <td align="center">
              {{ item.count + "x" }}
            </td>
            <td align="center">
              <v-switch
                v-model="item.visible"
                label="visible"
                color="primary"
                @change="changeLicenseTemplateVisibility(item)"
              ></v-switch>
            </td>
            <td align="center">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    icon
                    color="primary"
                    @click="
                      selectedLicensetemplate = item;
                      dialog = true;
                    "
                  >
                    <v-icon>mdi-open-in-new</v-icon>
                  </v-btn>
                </template>
                <span>Show</span>
              </v-tooltip>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    icon
                    color="primary"
                    @click="delTemplate(item.id)"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="dialog" max-width="1500" persistent>
      <licensetemplate-dialog
        v-if="dialog"
        :licensetemplate="selectedLicensetemplate"
        @done="dialog = false"
      ></licensetemplate-dialog>
    </v-dialog>
  </v-card>
</template>

<script>
import licensetemplateDialog from "./license_settings_licensetemplate_dialog.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "licensetemplatesCard",
  components: {
    licensetemplateDialog,
  },
  props: {
    search: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      licensetemplates: "allTemplates",
    }),
  },
  data: () => ({
    dialog: false,
    selectedLicensetemplate: null,
    headers: [
      {
        text: "Application",
        value: "applicationid",
        align: "center",
        sortable: false,
        filterable: true,
      },
      {
        text: "Name",
        value: "name",
        align: "center",
        sortable: false,
        filterable: true,
      },
      {
        text: "Description",
        value: "description",
        align: "center",
        sortable: false,
        filterable: true,
      },
      {
        text: "Price",
        value: "price",
        align: "center",
        sortable: false,
        filterable: true,
      },
      {
        text: "Monthly Price",
        value: "monthlyprice",
        align: "center",
        sortable: false,
        filterable: true,
      },
      {
        text: "Template in use",
        value: "inLicenseInUse",
        align: "center",
        sortable: false,
        filterable: true,
      },
      {
        text: "",
        value: "visible",
        align: "center",
        sortable: false,
        filterable: false,
      },
      {
        text: "",
        value: "actions",
        align: "center",
        sortable: false,
        filterable: false,
      },
    ],
  }),
  mounted() {
    this.$store.dispatch("fetchTemplates");
  },
  methods: {
    ...mapActions({
      deleteTemplate: "deleteTemplate",
      showSnackbar: "showSnackbar",
      updateLicenseTemplate: "updateLicenseTemplate",
    }),
    changeLicenseTemplateVisibility: function (item) {
      this.updateLicenseTemplate(item).then((error) => {
        if (error) {
          this.showSnackbar("Could not update Template: ", error);
        } else {
          this.showSnackbar("Template successfully updated");
        }
      });
    },
    customFilter: function (_, search, filter) {},
    delTemplate: function (licensetemplate) {
      this.deleteTemplate(licensetemplate).then((error) => {
        if (error) {
          this.showSnackbar("Could not delete Template: ", error);
        } else {
          this.showSnackbar("Template successfully deleted");
        }
      });
    },
  },
};
</script>

<style>
</style>