<template>
  <v-card height="100%">
    <v-card-title>
      <v-container fluid>
        <v-row>
          <v-col
            cols="6"
            align="left"
            class="nopad_class"
          >
            <v-container
              align="left"
              class="nopad_class"
            >
              <span
                align="left"
                class="title_class"
              >
                Modules
              </span>
            </v-container>
          </v-col>
          <v-col
            cols="6"
            align="right"
            class="nopad_class"
          >
            <v-btn
              outlined
              color="#b31616"
              @click="selectedModule=null;dialog=true;"
            >
              New
              <v-icon
                small
                class="ml-1"
              >
                mdi-plus
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :items="modules"
        :custom-filter="customFilter"
        :headers="headers"
        :items-per-page="5"
        :search="search"
      >
        <template v-slot:item="{ item }">
          <tr  align="center">
            <td> {{ item.applicationid }}</td>
            <td> {{ item.modulename }}</td>
            <td> {{ item.moduledescription }}</td>
            <td> {{ item.price ? (item.price + '€') : '-' }}</td>
            <td> {{ item.monthlyprice ? (item.monthlyprice + '€') : '-' }}</td>
            <td align="right">
              <v-tooltip right>
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    icon
                    color="#b31616"
                    @click="selectedModule=item;dialog=true"
                  >
                    <v-icon>mdi-cog-outline</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-on="on"
                    v-bind="attrs"
                    color="#b31616"
                    @click="
                      selectedModule = item;
                      deleteDialog = true;
                    "
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>

    
    <accept-dialog
      v-model="deleteDialog"
      v-if="deleteDialog"
      title="Delete Module?"
      text="Are you sure you want to delete this module?"
      @accepted="deleteModuleById(selectedModule.moduleid)"
    >
    </accept-dialog>

    <v-dialog
      v-model="dialog"
      max-width="1500"
      persistent
    >
      <module-dialog
        v-if="dialog"
        :module="selectedModule"
        @done="dialog=false"
      ></module-dialog>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moduleDialog from "./license_settings_module_dialog.vue";
import acceptDialog from "./helpers/generic_accept_dialog.vue";
export default {
  name: "modulesCard",
  components: {
    moduleDialog,
    acceptDialog,
  },
  props: {
    search: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({ modules: "allModules" }),
  },
  data: () => ({
    deleteDialog: false,
    dialog: false,
    selectedModule: null,
    headers: [
      {
        text: "Application",
        value: "application",
        align: "center",
        sortable: false,
        filterable: true,
      },
      {
        text: "Name",
        value: "name",
        align: "center",
        sortable: false,
        filterable: true,
      },
      {
        text: "Description",
        value: "description",
        align: "center",
        sortable: false,
        filterable: true,
      },
      {
        text: "Price",
        value: "price",
        align: "center",
        sortable: false,
        filterable: true,
      },
      {
        text: "Monthly Price",
        value: "monthlyprice",
        align: "center",
        sortable: false,
        filterable: true,
      },
      {
        text: "",
        value: "actions",
        align: "center",
        sortable: false,
        filterable: true,
      },
    ],
  }),
  mounted() {
    this.$store.dispatch("fetchModules");
  },
  methods: {
    ...mapActions({
      deleteModule: "deleteModule",
      showSnackbar: "showSnackbar",
    }),
    customFilter: function (_, search, filter) {},
    deleteModuleById(moduleid){
      const error = this.deleteModule(moduleid);
      if (error) {
        this.showSnackbar("Error while deleting Application. Error:" + error);
      }
      this.showSnackbar("Application successfuly deleted");
    }
  },
};
</script>

<style>
</style>