<template>
  <v-card>
    <v-card-title>
      <menuTitle
        title="License Settings"
        class="mt-6"
      ></menuTitle>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          align="center"
        >
          <v-text-field
            v-model="search"
            label="Search..."
          ></v-text-field>
        </v-col>

        <v-col
          cols="6"
          align="left"
        >
          <!-- Applications -->
          <applications-card :search="search"></applications-card>
        </v-col>

        <v-col
          cols="6"
          align="left"
        >
          <!-- Modules -->
          <modules-card :search="search">

          </modules-card>
        </v-col>

        <v-col
          cols="12"
          align="left"
        >
          <!-- License Templates -->
          <licensetemplates-card :search="search">

          </licensetemplates-card>
        </v-col>
      </v-row>

    </v-card-text>

    <snack
      :show="snackShow"
      :timeout="2000"
    >
      <span>{{ snackText }}</span>
    </snack>

  </v-card>
</template>
<script>
import applicationsCard from "../../components/license_settings_applications_card.vue";
import modulesCard from "../../components/license_settings_modules_card.vue";
import licensetemplatesCard from "../../components/license_settings_licensetemplates_card.vue";
import menuTitle from "../../components/helpers/menu_title.vue";
import snack from "../../components/helpers/snackbar.vue";
export default {
  name: "LicenseSettings",
  components: {
    menuTitle,
    snack,
    applicationsCard,
    modulesCard,
    licensetemplatesCard,
  },
  props: {},
  data: () => ({
    snackShow: false,
    snackText: "",
    search: "",
  }),
  methods: {
    error: function (message) {
      this.snackText = message;
      this.snackShow = true;
    },
  },
  mounted() {},
};
</script>