import { render, staticRenderFns } from "./license_settings_applications_card.vue?vue&type=template&id=2d4c28de&"
import script from "./license_settings_applications_card.vue?vue&type=script&lang=js&"
export * from "./license_settings_applications_card.vue?vue&type=script&lang=js&"
import style0 from "./license_settings_applications_card.vue?vue&type=style&index=0&id=2d4c28de&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports